import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import FinancialReport from 'components/Reporting/Profitability/FinancialReport';
import { PageTitles } from 'poly-constants';
import React from 'react';
import { useSelector } from 'store';

import ReportingProfitabilityEmployeesCostsTable from './ReportingProfitabilityEmployeesCostsTable';

export default function ReportingProfitabilityMissionView() {
  const currentMission = useSelector((state) => state.activity.currentMission);

  return (
    <PageWrapper missionView>
      <TabHeader title={PageTitles.profitabilityMissionPage} />

      <ReportingProfitabilityEmployeesCostsTable />
      <FinancialReport activity={currentMission} />
    </PageWrapper>
  );
}
