import { Stack, TableRow } from '@mui/material';
import {
  BorderedTableCell,
  CenteredTableCell,
} from 'components/commons/Tables/FlatTable';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

interface ProfitabilityMissionViewTableHeaderColumnsNamesProps {
  monthsToDisplay: moment.Moment[];
}

export function ProfitabilityMissionViewTableHeaderColumnsNames({
  monthsToDisplay,
}: ProfitabilityMissionViewTableHeaderColumnsNamesProps) {
  return (
    <TableRow sx={{ tableLayout: 'fixed', width: '100%' }}>
      <BorderedTableCell sx={{ width: '150px' }}>
        Collaborateur
      </BorderedTableCell>
      {_.map(monthsToDisplay, (month) => (
        <React.Fragment key={`headerTitle-${month.format('MMM')}`}>
          <CenteredTableCell sx={{ whiteSpace: 'normal' }}>
            <div>TJ Coût</div>
          </CenteredTableCell>

          <CenteredTableCell sx={{ whiteSpace: 'normal' }}>
            <div>Jours déclarés</div>
          </CenteredTableCell>

          <CenteredTableCell sx={{ whiteSpace: 'normal' }}>
            <Stack alignItems="center">Coût</Stack>
          </CenteredTableCell>
        </React.Fragment>
      ))}
    </TableRow>
  );
}
