import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import dayjs from 'dayjs';
import {
  BillingPurchaseOrderCurrencyChoices,
  useRevenueGraphForActivityQuery,
} from 'generated/graphql';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useState } from 'react';

interface ActivityRevenueGraphProps {
  activityId: string;
  startDate: Date;
  endDate: Date;
}

const formatRevenue = (value: number) =>
  totalFormat(value, BillingPurchaseOrderCurrencyChoices.Eur, true);

const ActivityRevenueGraph = ({
  activityId,
  startDate,
  endDate,
}: ActivityRevenueGraphProps) => {
  const [isCumulative, setIsCumulative] = useState<boolean>(true);

  const { data, loading } = useRevenueGraphForActivityQuery({
    variables: {
      activityId,
      isCumulative,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    },
  });

  const handleToggleChange = () => {
    setIsCumulative((state) => !state);
  };

  return (
    <Section>
      <Stack direction="row" gap={3}>
        <SectionTitle>Chiffre d’affaires</SectionTitle>
        <FormGroup>
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              control={
                <Switch onChange={handleToggleChange} checked={isCumulative} />
              }
              label="Données cumulatives"
              sx={{ mr: 1 }}
            />
            <Tooltip
              title={
                isCumulative
                  ? 'Données cumulatives selon la plage de date sélectionnée'
                  : 'Données indépendantes propres à chaque mois'
              }
              placement="top"
            >
              <InfoOutlined sx={{ color: 'text.secondary' }} fontSize="small" />
            </Tooltip>
          </Stack>
        </FormGroup>
      </Stack>
      <Box sx={{ width: '100%', height: 400, alignContent: 'center' }}>
        {loading ? (
          <LoadingPlaceholder />
        ) : (
          <BarChart
            margin={{ top: 10, bottom: 80, left: 65 }}
            dataset={
              data?.revenueGraphForActivity &&
              data?.revenueGraphForActivity.length > 0
                ? data?.revenueGraphForActivity
                : [{}]
            }
            series={[
              {
                dataKey: 'estimation',
                label: 'CA prévisionnel',
                valueFormatter: formatRevenue,
                stack: 'total',
              },
              {
                dataKey: 'revenue',
                label: 'CA réalisé',
                valueFormatter: formatRevenue,
              },
            ]}
            xAxis={[
              {
                dataKey: 'month',
                scaleType: 'band',
                valueFormatter: (month) =>
                  dayjs(month).locale('fr').format('MMM[\n]YYYY'),
              },
            ]}
            yAxis={[
              {
                valueFormatter: (value) => `${value / 1000} K€`,
              },
            ]}
            slotProps={{
              legend: {
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: 0,
              },
            }}
          />
        )}
      </Box>
    </Section>
  );
};

export default ActivityRevenueGraph;
