import { styled, SxProps, TableCell, Theme } from '@mui/material';
import {
  EmployeeNode,
  EmployeesEmployeeContractTypeChoices,
  ReportingEmployeeReportingParameterGradeChoices,
} from 'generated/graphql';
import { Moment } from 'moment';
import React from 'react';

import ProfitabilityRenderAmount from './GlobalView/ProfitabilityTableBody/Rows/Cells/ProfitabilityRenderAmount';

export const StyledCell = styled(TableCell)(() => ({
  paddingRight: 0,
  paddingLeft: 0,
  textAlign: 'center',
  whiteSpace: 'nowrap',
}));

function getRentability(
  isShowingMarginRate: boolean,
  ca: number | undefined,
  cost: number
) {
  if (!isShowingMarginRate) {
    return (ca || 0) - cost;
  }
  return ca ? ((ca - cost) / ca) * 100 : undefined;
}

interface ProfitabilityMarginPercentageCellProps {
  isShowingMarginRate: boolean;
  ca: number | undefined;
  cost: number;
  cellSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  wrapThousands?: boolean;
}

export function ProfitabilityMarginPercentageCell({
  isShowingMarginRate,
  ca,
  cost,
  cellSx,
  sx,
  wrapThousands = false,
}: ProfitabilityMarginPercentageCellProps) {
  const rentability = getRentability(isShowingMarginRate, ca, cost);

  return (
    <StyledCell sx={cellSx}>
      <div>
        <ProfitabilityRenderAmount
          amount={rentability}
          unit={isShowingMarginRate ? 'percentage' : 'currency'}
          sx={sx}
          wrapThousands={wrapThousands}
        />
      </div>
    </StyledCell>
  );
}

export function isEmployeeMatchingForReportingGrade(
  employee: EmployeeNode,
  grade: ReportingEmployeeReportingParameterGradeChoices
): boolean {
  if (grade === ReportingEmployeeReportingParameterGradeChoices.Intern) {
    return isEmployeeIntern(employee.contractType);
  }
  if (employee.grade === undefined) {
    return (
      grade === ReportingEmployeeReportingParameterGradeChoices.Collaborator
    );
  }
  return (
    grade === employee.grade.toString() &&
    (employee.contractType === EmployeesEmployeeContractTypeChoices.Cdi ||
      employee.contractType === EmployeesEmployeeContractTypeChoices.Cdd)
  );
}

export function isEmployeeIntern(
  contractType: EmployeesEmployeeContractTypeChoices
) {
  return (
    contractType === EmployeesEmployeeContractTypeChoices.Intern ||
    contractType === EmployeesEmployeeContractTypeChoices.InternPreJob ||
    contractType === EmployeesEmployeeContractTypeChoices.StudentApprentice
  );
}

export function enableMonthIncrement(month: Moment) {
  return !month.clone().endOf('years').isSame(month, 'month');
}

export function enableMonthDecrement(month: Moment) {
  return !month.clone().startOf('years').isSame(month, 'month');
}
export const DEFAULT_OCCUPATION = 'default';
