import LaunchIcon from '@mui/icons-material/Launch';
import { FormControl, Link, Stack, Typography } from '@mui/material';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import { ActivityNode, useUpdateRefActivityMutation } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface ActivityReferenceModalProps {
  activity?: ActivityNode;
  open: boolean;
  handleClose: () => void;
}

const validateURL = (value: string) => {
  try {
    new URL(value);
    return true;
  } catch (error) {
    return 'Ce champ doit contenir une URL valide.';
  }
};

export default function ActivityReferenceModal({
  activity,
  open,
  handleClose,
}: ActivityReferenceModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [updateRefActivity] = useUpdateRefActivityMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      refActivity: activity?.billingInformation?.refActivity ?? 'https://',
    },
  });

  const handleUpdateRefActivity = async (formValues: {
    activityId: string;
    refActivity: string;
  }) => {
    if (isEdit) {
      await updateRefActivity({
        variables: {
          activityId: activity?.id || '',
          refActivity: formValues.refActivity,
        },
      });
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  };

  const onClose = () => {
    setIsEdit(false);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      reset({
        refActivity: activity?.billingInformation?.refActivity ?? 'https://',
      });
    }
  }, [activity?.billingInformation?.refActivity, open, reset]);

  return (
    <PolyModal
      maxWidth={'sm'}
      fullWidth
      open={open}
      onClose={onClose}
      polyDialogTitle={
        isEdit ? 'Modifier la référence mission' : 'Référence mission'
      }
      handlePrimary={handleSubmit(handleUpdateRefActivity)}
      primaryButtonName={isEdit ? 'Enregistrer' : 'Modifier'}
      handleSecondary={() => (isEdit ? setIsEdit(false) : onClose())}
      secondaryButtonName={isEdit ? 'Annuler' : 'Fermer'}
    >
      <Stack flexDirection="column" gap={3}>
        {isEdit && (
          <FormControl fullWidth>
            <Controller
              rules={{ validate: validateURL }}
              control={control}
              name="refActivity"
              render={(params) => {
                return (
                  <LabelTextFieldGrid
                    {...params}
                    title="Référence Mission"
                    label="Référence Mission"
                    errorform={errors.refActivity}
                    customspacingtop={0}
                    customspacingside={0}
                  />
                );
              }}
            />
          </FormControl>
        )}
        {!isEdit &&
          (activity?.billingInformation?.refActivity ? (
            <Link
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              href={activity?.billingInformation?.refActivity}
              target="_blank"
              rel="noopener"
            >
              <LaunchIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography
                sx={{
                  color: 'inherit',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {activity?.billingInformation?.refActivity}
              </Typography>
            </Link>
          ) : (
            <Typography variant="body">Pas de référence mission</Typography>
          ))}
      </Stack>
    </PolyModal>
  );
}
