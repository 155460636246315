import { Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

export default function BillableStaffingHeader(): ReactElement {
  return (
    <Stack direction="row" sx={{ alignItems: 'center' }}>
      <Typography sx={{ mr: 0.5 }} variant={'h2Bold'} component="h2">
        Analyse du staffing
      </Typography>
    </Stack>
  );
}
