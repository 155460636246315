import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Stack } from '@mui/material';
import PolyTitle from 'components/commons/PolyTitle';
import AdrContextProvider from 'components/Reporting/Adr/AdrContextProvider';
import OccupationAdrAddButton from 'components/Reporting/Adr/costByRole/OccupationAdrAddButton';
import OccupationAdrExportButton from 'components/Reporting/Adr/costByRole/OccupationAdrExportButton';
import OccupationAdrTable from 'components/Reporting/Adr/costByRole/table/OccupationAdrTable';
import EditSaveButton from 'components/Reporting/Adr/EditSaveButton';
import ProviderAdrExportButton from 'components/Reporting/Adr/ProviderAdrExportButton';
import ProviderAdrTable from 'components/Reporting/Adr/ProviderAdrTable';
import ShowProviderAdrHistoryButton from 'components/Reporting/Adr/ShowProviderAdrHistoryButton';
import { DEFAULT_OCCUPATION } from 'components/Reporting/Profitability/utils';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  EmployeeReportingParameterNode,
  ReportingEmployeeReportingParameterGradeChoices,
  useAllEmployeeOccupationsWithoutReportingParameterQuery,
  useAllEmployeeReportingParametersQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';

export interface ADRByRole {
  occupation: string;
  intern: number;
  collaborator: number;
  coman: number;
  comex: number;
}

function newAdrByRole(occupation: string): ADRByRole {
  return {
    occupation: occupation,
    intern: 0,
    collaborator: 0,
    coman: 0,
    comex: 0,
  };
}

function updateADRByRole(
  adrByRole: ADRByRole,
  erp: EmployeeReportingParameterNode
) {
  switch (erp.grade) {
    case ReportingEmployeeReportingParameterGradeChoices.Intern:
      adrByRole.intern = erp.adr;
      break;
    case ReportingEmployeeReportingParameterGradeChoices.Collaborator:
      adrByRole.collaborator = erp.adr;
      break;
    case ReportingEmployeeReportingParameterGradeChoices.Coman:
      adrByRole.coman = erp.adr;
      break;
    case ReportingEmployeeReportingParameterGradeChoices.Comex:
      adrByRole.comex = erp.adr;
      break;
    default:
      throw new Error(
        `Grade "${erp.grade}" not in ReportingEmployeeReportingParameterGradeChoices`
      );
  }
  return adrByRole;
}

function getAllEmployeeReportingByRole(
  allEmployeeReporting: EmployeeReportingParameterNode[]
) {
  const allEmployeeReportingByRole = [
    { occupation: DEFAULT_OCCUPATION },
  ] as ADRByRole[];
  _.forEach(allEmployeeReporting, (erp) => {
    const occupationIndex = _.findIndex(
      allEmployeeReportingByRole,
      (adrByRole) => {
        return adrByRole.occupation === erp.occupation;
      }
    );
    if (occupationIndex !== -1) {
      allEmployeeReportingByRole[occupationIndex] = updateADRByRole(
        allEmployeeReportingByRole[occupationIndex],
        erp
      );
    } else {
      allEmployeeReportingByRole.push(
        updateADRByRole(newAdrByRole(erp.occupation), erp)
      );
    }
  });
  return allEmployeeReportingByRole;
}

export default function OccupationAdrPage() {
  const history = useHistory();
  const occupationAdrFeatureFlag = useEnvironmentVariable('occupation_adr');
  const currentYear = moment().year();
  const { data: dataErp, refetch: refetchErp } =
    useAllEmployeeReportingParametersQuery();
  const { data: dataAddableOccupations, refetch: refetchAddableOccupations } =
    useAllEmployeeOccupationsWithoutReportingParameterQuery();

  const refetch = () => {
    refetchErp();
    refetchAddableOccupations();
  };

  const addableOccupations =
    dataAddableOccupations?.allEmployeeOccupationsWithoutReportingParameter ||
    [];

  const allEmployeeReportingByRole = dataErp?.allEmployeeReportingParameters
    ? getAllEmployeeReportingByRole(
        dataErp?.allEmployeeReportingParameters as EmployeeReportingParameterNode[]
      )
    : [];

  return (
    <>
      <AdrContextProvider year={currentYear}>
        <Stack direction="row" mb={2} justifyContent="space-between">
          <Stack direction="row">
            <IconButton
              aria-label="back"
              onClick={() => history.goBack()}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
            <PolyTitle sx={{ mr: 3 }}>TJM Coûts par rôle Polyconseil</PolyTitle>
          </Stack>
          <Stack direction="row">
            {occupationAdrFeatureFlag && (
              <OccupationAdrAddButton
                occupations={addableOccupations}
                refetch={refetch}
              />
            )}
            <OccupationAdrExportButton year={currentYear} />
            <EditSaveButton />
          </Stack>
        </Stack>
        <OccupationAdrTable
          allEmployeeReportingByRole={allEmployeeReportingByRole}
          refetch={refetch}
        />
      </AdrContextProvider>

      <AdrContextProvider year={currentYear}>
        <Stack direction="row" mt={4} mb={2} justifyContent="space-between">
          <PolyTitle sx={{ mr: 1 }}>Prestataires</PolyTitle>
          <Stack direction="row">
            <ShowProviderAdrHistoryButton />
            <ProviderAdrExportButton year={currentYear} />
            <EditSaveButton />
          </Stack>
        </Stack>
        <ProviderAdrTable />
      </AdrContextProvider>
    </>
  );
}
