import { Box } from '@mui/material';
import { useBillableStaffingContext } from 'contexts/Reporting/BillableStaffingContextProvider';
import React, { ReactElement } from 'react';

import GraphFilter from '../Filter/GraphFilter';

export default function BillableStaffingFilter(): ReactElement {
  const { optionsSelected, setOptionsSelected } = useBillableStaffingContext();

  return (
    <Box sx={{ display: 'flex' }}>
      <GraphFilter
        optionsSelected={optionsSelected}
        setOptionsSelected={setOptionsSelected}
        isContractTypeFilterOn
        isGradeFilterOn
        isOccupationFilterOn
      />
    </Box>
  );
}
