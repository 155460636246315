import { Box } from '@mui/material';
import { ActiveElement, Chart, ChartEvent } from 'chart.js';
import { useBillableStaffingContext } from 'contexts/Reporting/BillableStaffingContextProvider';
import React, { MouseEvent, ReactElement, useRef, useState } from 'react';
import { Bar, getDatasetAtEvent, getElementAtEvent } from 'react-chartjs-2';

import BillableSectorModal from '../BillableSectorModal';
import UnbillableActivityModal from '../UnbillableActivityModal';
import { MONTH_IN_YEAR_LABELS } from '../utils';

export default function BillableStaffingGraph(): ReactElement {
  const [datasetIndex, setDatasetIndex] = useState<number>();
  const [monthIndex, setMonthIndex] = useState<number>();
  const [isPointer, setIsPointer] = useState<boolean>(false);

  const {
    staffingAnalysisList,
    billedEtpsData,
    unbillableEtpsData,
    undeclaredEtpsData,
    leaveEtpsData,
  } = useBillableStaffingContext();

  const billedEtps = {
    label: 'ETP Facturés',
    data: billedEtpsData,
    backgroundColor: 'rgb(34, 109, 104)',
    hoverBackgroundColor: 'rgba(34, 109, 104, 0.7)',
  };

  const unbillableEtps = {
    label: 'ETP Non Facturés',
    data: unbillableEtpsData,
    backgroundColor: 'rgb(74, 113, 174)',
    hoverBackgroundColor: 'rgba(74, 113, 174, 0.7)',
  };

  const undeclaredEtps = {
    label: 'ETP Non Déclarés',
    data: undeclaredEtpsData,
    backgroundColor: 'rgb(255, 99, 132)',
  };

  const leaveEtps = {
    label: 'Congés et Autres Absences',
    data: leaveEtpsData,
    backgroundColor: 'rgb(209, 211, 210)',
  };

  const chartData = {
    labels: MONTH_IN_YEAR_LABELS,
    datasets: [billedEtps, unbillableEtps, undeclaredEtps, leaveEtps],
  };

  function isSelectingBillableEtp(datasetIndex: number) {
    return chartData.datasets[datasetIndex].label === billedEtps.label;
  }

  function isSelectingUnbillableEtp(datasetIndex: number) {
    return chartData.datasets[datasetIndex].label === unbillableEtps.label;
  }

  const options = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
    onHover: (event: ChartEvent, chartElement: ActiveElement[]) => {
      if (!chartElement[0]) {
        setIsPointer(false);
        return;
      }
      if (
        chartElement[0].datasetIndex === 0 ||
        chartElement[0].datasetIndex === 1
      ) {
        setIsPointer(true);
        return;
      }
      setIsPointer(false);
    },
  };

  const chartRef = useRef<Chart<'bar'>>();

  return (
    <Box sx={{ cursor: isPointer ? 'pointer' : 'default' }}>
      <Bar
        ref={chartRef}
        data={chartData}
        options={options}
        onClick={(event: MouseEvent<HTMLCanvasElement>) => {
          const { current: chart } = chartRef;
          if (!chart) {
            return;
          }
          const datasets = getDatasetAtEvent(chart, event);
          if (!datasets[0]) {
            return;
          }
          const { datasetIndex } = datasets[0];
          if (
            !isSelectingBillableEtp(datasetIndex) &&
            !isSelectingUnbillableEtp(datasetIndex)
          ) {
            return;
          }

          const elements = getElementAtEvent(chart, event);
          const { index: monthIndex } = elements[0];

          setDatasetIndex(datasetIndex);
          setMonthIndex(monthIndex);
        }}
      />
      {monthIndex !== undefined &&
        datasetIndex !== undefined &&
        ((isSelectingBillableEtp(datasetIndex) && (
          <BillableSectorModal
            title={`${chartData.datasets[datasetIndex].label} : ${chartData.labels[monthIndex]}`}
            onClose={() => {
              setDatasetIndex(undefined);
              setMonthIndex(undefined);
            }}
            billableSectorStaffings={
              staffingAnalysisList[monthIndex].billableSectorStaffings
            }
            monthWorkDays={staffingAnalysisList[monthIndex].monthWorkDays}
          />
        )) ||
          (isSelectingUnbillableEtp(datasetIndex) && (
            <UnbillableActivityModal
              title={`${chartData.datasets[datasetIndex].label} : ${chartData.labels[monthIndex]}`}
              onClose={() => {
                setDatasetIndex(undefined);
                setMonthIndex(undefined);
              }}
              unbillableActivityStaffings={
                staffingAnalysisList[monthIndex].unbillableActivityStaffings
              }
              monthWorkDays={staffingAnalysisList[monthIndex].monthWorkDays}
            />
          )))}
    </Box>
  );
}
