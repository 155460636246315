import { Stack } from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import PolyDialogActions from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyDialogActions';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityNode,
  BusinessClientNode,
  EmployeeNode,
  useAllActiveAndFutureComexQuery,
  useAllActiveAndFutureEmployeesQuery,
  useAllBusinessClientsQuery,
  useCreateOrUpdateBusinessClientMutation,
  useCreateOrUpdateExternalActivityMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { SaveButtonName } from 'poly-constants';
import { default as React, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import ExternalActivityInfoForm from './ExternalActivityInfoForm';

interface UninitializedExternalActivityFormProps {
  billingType?: ActivitiesActivityBillingTypeChoices;
  handleCompleted: (createdActivity?: ActivityNode) => void;
  onClose: () => void;
}

export const UninitializedExternalActivityForm = ({
  billingType,
  handleCompleted,
  onClose,
}: UninitializedExternalActivityFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [businessClientsArray, setBusinessClientsArray] = useState<
    BusinessClientNode[]
  >([]);
  const isTM = billingType === ActivitiesActivityBillingTypeChoices.Tm;

  useAllBusinessClientsQuery({
    onCompleted: (data) => {
      if (data.allBusinessClients) {
        setBusinessClientsArray(
          data.allBusinessClients as BusinessClientNode[]
        );
      }
    },
  });

  const [employees, setEmployees] = useState<EmployeeNode[]>([]);
  useAllActiveAndFutureEmployeesQuery({
    onCompleted: (data) => {
      if (data?.allActiveAndFutureEmployees) {
        setEmployees(data.allActiveAndFutureEmployees as EmployeeNode[]);
      }
    },
  });

  const [allComexEmployees, setAllComexEmployees] = useState<EmployeeNode[]>(
    []
  );
  useAllActiveAndFutureComexQuery({
    onCompleted: (data) => {
      if (data?.allActiveAndFutureComex) {
        setAllComexEmployees(data.allActiveAndFutureComex as EmployeeNode[]);
      }
    },
  });

  const defaultValues = {
    name: '',
    type: ActivitiesActivityTypeChoices.Ext,
    billingType: billingType,
    isTm: isTM,
    client: '',
    selectedBusinessClient: undefined as unknown as BusinessClientNode,
    refMarket: '',
    selectedDirector: undefined as unknown as EmployeeNode,
    selectedChiefs: [] as EmployeeNode[],
    selectedLeadDevs: [] as EmployeeNode[],
    startDate: moment().format('YYYY-MM-DD'),
    expirationDate: moment().format('YYYY-MM-DD'),
    description: '',
    techStack: '',
  };

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
  });

  const { errors, handleSubmit, trigger, watch } = form;

  const { data: businessClientsData, refetch: refetchBusinessClients } =
    useAllBusinessClientsQuery();

  const [createOrUpdateBusinessClientMutation] =
    useCreateOrUpdateBusinessClientMutation({
      onCompleted: (data) => {
        const clientName =
          data.createOrUpdateBusinessClient?.businessClient?.name || '';
        enqueueSnackbar(`Le client « ${clientName} » a bien été créé`, {
          variant: 'success',
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });
  async function handleSaveClient(name: string) {
    const { data } = await createOrUpdateBusinessClientMutation({
      variables: {
        name: name,
      },
    });

    await refetchBusinessClients();

    const newClient = data?.createOrUpdateBusinessClient?.businessClient;
    if (newClient) {
      return data;
    }
  }

  const [createOrUpdateExternalActivityMutation] =
    useCreateOrUpdateExternalActivityMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      onCompleted: (data) => {
        const createdActivity = data.createOrUpdateExternalActivity
          ?.activity as ActivityNode;
        enqueueSnackbar(
          `La mission « ${createdActivity.name} » a bien été créée`,
          {
            variant: 'success',
          }
        );
        handleCompleted?.(createdActivity);
      },
    });

  function isFormRight() {
    return _.isEmpty(errors);
  }

  const onSubmit = async (submitValues: typeof defaultValues) => {
    const allBusinessClientsName = businessClientsData?.allBusinessClients?.map(
      (client) => client.name
    );
    if (
      !allBusinessClientsName?.includes(
        submitValues.selectedBusinessClient.name
      )
    ) {
      const newClientData = await handleSaveClient(
        watch('selectedBusinessClient').name
      );
      submitValues.selectedBusinessClient = newClientData
        ?.createOrUpdateBusinessClient?.businessClient as BusinessClientNode;
    }
    await trigger([
      'name',
      'selectedBusinessClient',
      'selectedDirector',
      'selectedChiefs',
      'startDate',
      'expirationDate',
    ]);

    await createOrUpdateExternalActivityMutation({
      variables: {
        name: submitValues.name,
        type: ActivitiesActivityTypeChoices.Ext,
        billingType: billingType || '',
        client: submitValues.selectedBusinessClient?.name,
        businessClientId: submitValues.selectedBusinessClient?.id,
        directorId: submitValues.selectedDirector?.id,
        chiefsIds: _.flatMap(submitValues.selectedChiefs, 'id'),
        leadDevsIds: _.flatMap(submitValues.selectedLeadDevs, 'id'),
        startDate: moment(submitValues.startDate).format('YYYY-MM-DD'),
        expirationDate: moment(submitValues.expirationDate).format(
          'YYYY-MM-DD'
        ),
        description: submitValues.description,
        techStack: submitValues.techStack,
      },
    });
  };

  const actionButtons = (
    <PolyDialogActions
      key={'actions-buttons'}
      primaryButtonName={SaveButtonName}
      handleSecondary={onClose}
      primaryButtonType={'submit'}
      isPrimaryButtonDisabled={!isFormRight()}
    />
  );

  const alertMessage = `Veuillez remplir toutes les informations de la mission. Celle-ci sera
        créée comme non initialisée jusqu'à ce que vous remplissiez les détails
        de facturation.`;

  return (
    <FormProvider {...form}>
      <PolyAlert severity="info" variant="outlined" sx={{ mb: 1, mx: 2 }}>
        {alertMessage}
      </PolyAlert>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <ExternalActivityInfoForm
          employees={employees}
          allComexEmployees={allComexEmployees}
          businessClients={businessClientsArray}
        />
        <Stack direction={'row'} justifyContent={'right'} mx={1}>
          {actionButtons}
        </Stack>
      </form>
    </FormProvider>
  );
};
