import { InfoOutlined } from '@mui/icons-material';
import {
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import { LineChart } from '@mui/x-charts';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'; // ES 2015
import { useAdrGraphForActivityQuery } from 'generated/graphql';
import React, { useState } from 'react';

interface ActivityAdrGraphProps {
  activityId: string;
  startDate: Date;
  endDate: Date;
}

const ActivityAdrGraph = ({
  activityId,
  startDate,
  endDate,
}: ActivityAdrGraphProps) => {
  const [isCumulative, setIsCumulative] = useState<boolean>(true);

  const { data, loading } = useAdrGraphForActivityQuery({
    variables: {
      activityId,
      isCumulative,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    },
  });

  require('dayjs/locale/fr');
  dayjs.extend(isBetween);

  const handleToggleChange = () => {
    setIsCumulative((state) => !state);
  };

  return (
    <Section>
      <Stack direction="row" gap={3}>
        <SectionTitle>TJM vendu / coût</SectionTitle>
        <FormGroup>
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              control={
                <Switch onChange={handleToggleChange} checked={isCumulative} />
              }
              label="Données cumulatives"
              sx={{ mr: 1 }}
            />
            <Tooltip
              title="Les données cumulatives reprennent l'ensemble des valeurs de la plage de date sélectionnée pour vous donner la moyenne : ΣCA (des mois 1 à n) / Σ jours déclarés (des mois 1 à n) alors que les données non cumulatives reprennent les valeurs indépendantes de chaque mois CA mois X / Jours déclarés sur mois X"
              placement="top"
            >
              <InfoOutlined sx={{ color: 'text.secondary' }} fontSize="small" />
            </Tooltip>
          </Stack>
        </FormGroup>
      </Stack>
      <Box sx={{ width: '100%', height: 400, alignContent: 'center' }}>
        {loading ? (
          <LoadingPlaceholder />
        ) : (
          <LineChart
            margin={{ top: 10, bottom: 80 }}
            series={[
              {
                dataKey: 'adrSold',
                label: 'TJM Vendu',
                valueFormatter: formatAdr,
              },
              {
                dataKey: 'adrCost',
                label: 'TJM Coût',
                valueFormatter: formatAdr,
              },
            ]}
            xAxis={[
              {
                dataKey: 'month',
                scaleType: 'point',
                valueFormatter: (month) =>
                  dayjs(month).locale('fr').format('MMM[\n]YYYY'),
              },
            ]}
            yAxis={[{ valueFormatter: (value) => `${value} €` }]}
            dataset={
              data?.adrGraphForActivity && data?.adrGraphForActivity.length > 0
                ? data?.adrGraphForActivity?.filter((activity) =>
                    dayjs(activity.month).isBetween(
                      startDate,
                      endDate,
                      'month',
                      '[]'
                    )
                  )
                : [{}]
            }
            slotProps={{
              legend: {
                position: { vertical: 'bottom', horizontal: 'middle' },
              },
            }}
          />
        )}
      </Box>
    </Section>
  );
};

const formatAdr = (adr: number) => `${adr.toFixed(2)} €`;

export default ActivityAdrGraph;
