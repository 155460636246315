import { Box, Divider, Stack, Typography } from '@mui/material';
import { QuitEditButton } from 'components/ActivityMonitoring/ValidateTmActivityMonitoring/EditButton';
import ValidateButton from 'components/ActivityMonitoring/ValidateTmActivityMonitoring/ValidateButton';
import { useTableContext } from 'contexts/ValidateTmActivityMonitoring/TableContextProvider';
import React, { useEffect } from 'react';
import { useSelector } from 'store';

import ActionsMenu, { Option } from '../ActionsMenu/ActionsMenu';
import PolyTitle from '../PolyTitle';
import MissionHeader from './MissionHeader';

interface TabHeaderProps {
  title: React.ReactNode | string;
  titleIcon?: React.ReactNode;
  count?: number;
  yearSelectorComponent?: React.ReactNode;
  filterComponents?: React.ReactNode;
  actionComponents?: JSX.Element;
  buttonComponent?: JSX.Element;
  ignoreMissionHeader?: boolean;
  showPinButton?: boolean;
}

function TabHeader({
  title,
  titleIcon,
  count = 0,
  yearSelectorComponent,
  filterComponents,
  actionComponents,
  buttonComponent,
  ignoreMissionHeader = false,
  showPinButton = true,
}: TabHeaderProps) {
  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginBottom={2}
      >
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <PolyTitle>{title}</PolyTitle>
            {titleIcon}
          </Stack>
          {count > 0 && (
            <Typography variant={'h2Bold'} color="text.secondary">
              ({count})
            </Typography>
          )}
          {yearSelectorComponent}
        </Stack>
        {actionComponents && <Actions>{actionComponents}</Actions>}
        {buttonComponent}
      </Stack>
      {!ignoreMissionHeader && (
        <MissionHeaderWrapper showPinButton={showPinButton} />
      )}
      <Divider
        sx={{
          mb: 2,
          mt: 3,
          borderColor: 'standardGrey.dark',
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      />
      {filterComponents}
    </Box>
  );
}

export default TabHeader;

function Actions({ children }: { children?: JSX.Element }) {
  const hiddenbuttons = React.useRef<HTMLDivElement>(null);
  const [options, setOptions] = React.useState<Option[]>([]);
  const { mode } = useTableContext();
  const groupEdit = useSelector((state) => state.purchaseOrder?.groupEdit);
  const selectedBills = useSelector(
    (state) => state.purchaseOrder?.selectedBills
  );
  const isSelectedBills = selectedBills && selectedBills.length > 0;

  useEffect(() => {
    if (mode === 'validation' || mode === 'edit') {
      return;
    }
    if (hiddenbuttons.current) {
      const buttons = hiddenbuttons.current
        .children as HTMLCollectionOf<HTMLElement>;
      const buttonArray = [...buttons];
      const actions = buttonArray.map((button) => {
        return {
          title: button.textContent?.toUpperCase(),
          icon: button.children[0]?.innerHTML ? (
            <Box
              dangerouslySetInnerHTML={{ __html: button.children[0].innerHTML }}
              sx={{
                display: 'flex',
              }}
            />
          ) : (
            <></>
          ),
          onClick: () => {
            button.click();
          },
          display: true,
        } as Option;
      });
      setOptions(actions);
    }
  }, [mode, groupEdit, isSelectedBills]);

  if (mode === 'validation') {
    return <ValidateButton />;
  }
  if (mode === 'edit') {
    return <QuitEditButton />;
  }

  return (
    <>
      {options.length > 1 && <ActionsMenu options={options} />}
      <Stack
        direction={'row'}
        gap={2}
        alignItems={'center'}
        ref={hiddenbuttons}
        sx={{
          display: options.length > 1 ? 'none' : 'flex',
        }}
      >
        {children}
      </Stack>
    </>
  );
}

function MissionHeaderWrapper({ showPinButton }: { showPinButton?: boolean }) {
  const currentMission = useSelector((state) => state.activity.currentMission);
  const refetchCurrentMission = useSelector(
    (state) => state.activity.refetchCurrentMission
  );
  const currentMissionId = currentMission?.id;
  if (currentMissionId === undefined) {
    return <></>;
  }
  return (
    <MissionHeader
      mission={currentMission}
      refetchMission={refetchCurrentMission}
      showStatusChip={false}
      showPinButton={showPinButton}
    />
  );
}
