import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Stack, Typography } from '@mui/material';
import MissionPageWrapper from 'components/commons/MissionPageWrapper';
import MissionStatusChip from 'components/commons/MissionStatusChip';
import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import PolyHeader from 'components/commons/PolyHeader';
import { MissionTitle } from 'components/commons/TabHeader/MissionTitle';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import PongoButton from 'components/MUIOverload/PongoButton';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import useQuery from 'hooks/useQuery';
import moment from 'moment';
import { POLY_DATE } from 'poly-constants';
import React, { Dispatch, SetStateAction, useState } from 'react';

import CftToExternalActivityHandler from './CftToExternalActivityHandler';
import { ExternalActivityModal } from './ExternalActivityModal/ExternalActivityModal';
import { HrActivityModal } from './HrActivityModal/HrActivityModal';
import { InternalActivityModal } from './InternalActivityModal/InternalActivityModal';

const ActivityHeader = ({
  activity,
  isEditable,
  refetchActivityInfo,
  setRefActivityModalVisibility,
}: {
  activity: ActivityNode;
  isEditable: boolean;
  refetchActivityInfo: () => void;
  setRefActivityModalVisibility: Dispatch<SetStateAction<boolean>>;
}) => {
  const query = useQuery();
  const queryOrigin = query.get('origin');
  const { activities } = getUrlList();
  const [missionModalVisibility, setMissionModalVisibility] = useState(false);
  const [isEditUninitialized, setIsEditUninitialized] = useState(false);
  const [activeModal, setActiveModal] =
    useState<ActivitiesActivityTypeChoices | null>(null);
  const [isModalContentLoading, setIsModalContentLoading] = useState(true);
  const isMissionTypeHR = activity.type === ActivitiesActivityTypeChoices.Hr;
  const isMissionExernal = activity.type === ActivitiesActivityTypeChoices.Ext;
  const isMissionUninitialized =
    activity.type === ActivitiesActivityTypeChoices.Ext &&
    !activity.billingInformation;
  const isMissionTypeCFT = activity.type === ActivitiesActivityTypeChoices.Cft;
  const ADMIN_BACK_OFFICE_FLAG = useEnvironmentVariable('admin_back_office');

  const handleClick = (activityType: ActivitiesActivityTypeChoices) => {
    ADMIN_BACK_OFFICE_FLAG
      ? setActiveModal(activityType)
      : setMissionModalVisibility(true);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
    setIsEditUninitialized(false);
  };

  return (
    <>
      <PolyHeader
        goBackLocation={(queryOrigin || activities) as unknown as Location}
        sx={{ mb: 2, '>:first-of-type': { p: 0, mr: 1 } }}
      >
        <Typography>Retour</Typography>
      </PolyHeader>
      <Stack flexDirection="row" justifyContent="space-between" mb={2}>
        <Stack gap={1}>
          <MissionTitle mission={activity} />
          {!isMissionTypeHR && (
            <>
              <Stack direction="row" gap={1.5} alignItems="center">
                <Typography variant="h2" color={'darkGrey.darker'}>
                  {activity?.businessClient?.name}
                </Typography>
                <MissionStatusChip activity={activity} />
              </Stack>
              <Typography color="textSecondary">
                Du {moment(activity.startDate).format(POLY_DATE)} au{' '}
                {moment(activity.expirationDate).format(POLY_DATE)}
              </Typography>
            </>
          )}
        </Stack>
        {isEditable && (
          <>
            {isMissionTypeCFT ? (
              <Stack direction="row" gap={1}>
                <PongoButton
                  variant="contained"
                  buttonStyle="secondary"
                  startIcon={<SettingsRoundedIcon />}
                  onClick={() => handleClick(activity.type)}
                  sx={{ minWidth: 0 }}
                />
                <CftToExternalActivityHandler
                  activity={activity}
                  refetchActivityInfo={refetchActivityInfo}
                />
              </Stack>
            ) : (
              <Stack direction="row" gap={1}>
                {isMissionUninitialized && (
                  <PongoButton
                    variant="contained"
                    buttonStyle="secondary"
                    startIcon={<SettingsRoundedIcon />}
                    onClick={() => {
                      setIsEditUninitialized(true);
                      handleClick(activity.type);
                    }}
                    sx={{ minWidth: 0 }}
                  />
                )}
                {isMissionExernal && !isMissionUninitialized && (
                  <PongoButton
                    variant="contained"
                    buttonStyle={
                      activity.billingInformation?.refActivity
                        ? 'secondary'
                        : 'primary'
                    }
                    startIcon={
                      activity.billingInformation?.refActivity ? (
                        <VisibilityIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                    onClick={() => setRefActivityModalVisibility(true)}
                  >
                    {'Référence Mission'}
                  </PongoButton>
                )}
                <PongoButton
                  variant="contained"
                  startIcon={
                    isMissionUninitialized ? (
                      <PlayArrowIcon />
                    ) : (
                      <SettingsRoundedIcon />
                    )
                  }
                  onClick={() => handleClick(activity.type)}
                >
                  {isMissionUninitialized ? 'Initialiser' : 'Paramétrer'}
                </PongoButton>
              </Stack>
            )}
          </>
        )}
      </Stack>

      <HrActivityModal
        open={activeModal === ActivitiesActivityTypeChoices.Hr}
        onClose={() => handleCloseModal()}
        activity={activity}
      />

      <InternalActivityModal
        open={activeModal === ActivitiesActivityTypeChoices.Int}
        onClose={() => handleCloseModal()}
        activity={activity}
        refetchActivityInfo={refetchActivityInfo}
        title={activity.name}
      />

      <InternalActivityModal
        open={activeModal === ActivitiesActivityTypeChoices.Cft}
        onClose={() => handleCloseModal()}
        title={activity.name}
        activity={activity}
        isCftActivity
        refetchActivityInfo={refetchActivityInfo}
      />

      <ExternalActivityModal
        open={activeModal === ActivitiesActivityTypeChoices.Ext}
        activity={activity}
        onClose={() => handleCloseModal()}
        refetchActivityInfo={refetchActivityInfo}
        isEditUninitialized={isEditUninitialized}
      />

      <PolyModal
        open={missionModalVisibility}
        onClose={() => setMissionModalVisibility(false)}
        sx={{ overflow: 'scroll' }}
        maxWidth="md"
        polyDialogTitle={activity.name}
        polyDialogIcon={
          <MissionTypeIcon
            type={activity.type}
            isTmContracts={
              activity.billingType === ActivitiesActivityBillingTypeChoices.Tm
            }
            tooltip
          />
        }
        noSecondaryButton
        isLoading={isModalContentLoading}
      >
        <MissionPageWrapper
          isEditMode={isEditable}
          mission={activity}
          closeModal={() => setMissionModalVisibility(false)}
          handleLoadedContent={() => setIsModalContentLoading(false)}
        />
      </PolyModal>
    </>
  );
};

export default ActivityHeader;
