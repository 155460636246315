import { Stack } from '@mui/material';
import BillableStaffingContextProvider from 'contexts/Reporting/BillableStaffingContextProvider';
import React, { ReactElement } from 'react';

import { GraphProps } from '../utils';
import BillableStaffingExport from './BillableStaffingExport';
import BillableStaffingFilter from './BillableStaffingFilter';
import BillableStaffingHeader from './BillableStaffingHeader';
import GraphRenderer from './GraphRenderer';
import IntermissionRateSwitch from './IntermissionRateSwitch';

export default function BillableStaffingWrapper({
  year,
  isInJanuary,
}: GraphProps): ReactElement {
  return (
    <>
      <BillableStaffingContextProvider>
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          marginTop={6}
          marginLeft={4}
          marginBottom={3}
        >
          <BillableStaffingHeader />
          <>{!isInJanuary && <BillableStaffingFilter />}</>
          <BillableStaffingExport year={year} />
          <IntermissionRateSwitch />
        </Stack>
        <GraphRenderer isInJanuary={isInJanuary} year={year} />
      </BillableStaffingContextProvider>
    </>
  );
}
