import LaunchIcon from '@mui/icons-material/Launch';
import { Avatar, Stack, Typography, useTheme } from '@mui/material';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import { getEmployeeOccupationInfo } from 'components/Phonebook/EmployeeCard/EmployeeOccupationIndicator/EmployeeOccupationIndicator';
import { EmployeeNode } from 'generated/graphql';
import { useImageRequest } from 'hooks/useImageRequest';
import { AVATAR_PONGO_PATH } from 'poly-constants';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import urljoin from 'url-join';
import { displayEmployeeNode } from 'utils';

interface AvatarNameAndOccupationStackProps {
  employee: EmployeeNode;
  isClickable?: boolean;
  disabled?: boolean;
  withOccupation?: boolean;
}

export default function AvatarNameAndOccupationStack({
  employee,
  isClickable,
  disabled,
  withOccupation = true,
}: AvatarNameAndOccupationStackProps) {
  const { palette } = useTheme();

  const [image, setImage] = useState<string | undefined>(undefined);

  const { response: imageReceived, loading } = useImageRequest(
    employee?.minifiedProfilePicture?.filename
  );

  const { employees } = getUrlList();

  useEffect(() => {
    if (employee?.minifiedProfilePicture?.filename) {
      if (!loading) {
        setImage(imageReceived);
      }
    } else {
      setImage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee, imageReceived, loading]);

  return (
    <Stack direction="row" alignItems="center" width="inherit" spacing={1}>
      <Avatar src={image || AVATAR_PONGO_PATH} />
      <Stack
        width="inherit"
        color={disabled ? palette.darkGrey.dark : palette.text.primary}
      >
        <Typography
          variant={isClickable ? 'bodySemiBold' : 'bodyBold'}
          sx={{ width: 'fill-available', color: 'inherit' }}
          noWrap
        >
          {displayEmployeeNode(employee, false)}
        </Typography>
        {withOccupation && (
          <Typography
            variant={isClickable ? 'bodySLight' : 'bodyS'}
            sx={{ width: 'fill-available', color: 'inherit' }}
            noWrap
          >
            {
              getEmployeeOccupationInfo(employee.occupation, true)
                .occupationName
            }
          </Typography>
        )}
        {isClickable && (
          <Link to={urljoin(employees, employee.id)} target="_blank">
            <Stack alignItems="center" direction="row" gap={0.5}>
              <Typography
                variant="bodySMedium"
                color={palette.primary.main}
                sx={{ color: palette.primary.main }}
                noWrap
              >
                Voir profil
              </Typography>
              <LaunchIcon
                sx={{ color: palette.primary.main, fontSize: 'small' }}
              />
            </Stack>
          </Link>
        )}
      </Stack>
    </Stack>
  );
}
