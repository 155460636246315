import { Box, Stack } from '@mui/material';
import { useBillableStaffingContext } from 'contexts/Reporting/BillableStaffingContextProvider';
import {
  StaffingAnalysisNode,
  useStaffingAnalysisLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { ReactElement, useEffect } from 'react';

import { FilterOptionTypes } from '../Filter/utils';
import GraphSkeleton from '../GraphSkeleton';
import NoDataForThisYearAlert from '../NoDataForThisYearAlert';
import { GraphProps } from '../utils';
import BillableStaffingGraph from './BillableStaffingGraph';
import IntermissionRateGraph from './IntermissionRateGraph';

export default function GraphRenderer({
  year,
  isInJanuary,
}: GraphProps): ReactElement {
  const {
    isIntermissionRateView,
    optionsSelected,
    setStaffingAnalysisList,
    setBilledEtpsData,
    setUnbillableEtpsData,
    setUndeclaredEtpsData,
    setLeaveEtpsData,
  } = useBillableStaffingContext();

  const contractFilter = _.filter(
    optionsSelected,
    (option) => option.__typename === FilterOptionTypes.Contract
  ).map((option) => option.id);
  const gradeFilter = _.filter(
    optionsSelected,
    (option) => option.__typename === FilterOptionTypes.Grade
  ).map((option) => option.id);
  const occupationFilter = _.filter(
    optionsSelected,
    (option) => option.__typename === FilterOptionTypes.Occupation
  ).map((option) => option.id);

  const [billableStaffingGraphQuery, { loading }] =
    useStaffingAnalysisLazyQuery({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      onCompleted: (data) => {
        const staffingAnalysisList =
          data?.staffingAnalysis as StaffingAnalysisNode[];
        setStaffingAnalysisList(staffingAnalysisList);

        // assuming staffing analysis is already sorted by month

        setBilledEtpsData(
          _.map(
            staffingAnalysisList,
            ({ billableSectorStaffings, monthWorkDays }) =>
              Number.parseFloat(
                totalFormat(
                  _.sumBy(billableSectorStaffings, 'workedDays') / monthWorkDays
                ).replace(',', '.')
              )
          )
        );

        setUnbillableEtpsData(
          _.map(
            staffingAnalysisList,
            ({ unbillableActivityStaffings, monthWorkDays }) =>
              Number.parseFloat(
                totalFormat(
                  _.sumBy(unbillableActivityStaffings, 'workedDays') /
                    monthWorkDays
                ).replace(',', '.')
              )
          )
        );

        setUndeclaredEtpsData(
          _.map(
            staffingAnalysisList,
            ({ undeclaredWorkedDays, monthWorkDays }) =>
              Number.parseFloat(
                totalFormat(undeclaredWorkedDays / monthWorkDays).replace(
                  ',',
                  '.'
                )
              )
          )
        );

        setLeaveEtpsData(
          _.map(staffingAnalysisList, ({ leaveDays, monthWorkDays }) =>
            Number.parseFloat(
              totalFormat(leaveDays / monthWorkDays).replace(',', '.')
            )
          )
        );
      },
    });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    billableStaffingGraphQuery({
      variables: {
        year: year,
        contractFilter: contractFilter,
        gradeFilter: gradeFilter,
        occupationFilter: occupationFilter,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, optionsSelected, billableStaffingGraphQuery]);

  if (loading) {
    return <GraphSkeleton />;
  }

  return (
    <>
      {isInJanuary ? (
        <NoDataForThisYearAlert />
      ) : (
        <Stack direction="row" justifyContent="space-around">
          <Box sx={{ width: 0.8, aspectRatio: '2' }}>
            {isIntermissionRateView ? (
              <IntermissionRateGraph />
            ) : (
              <BillableStaffingGraph />
            )}
          </Box>
        </Stack>
      )}
    </>
  );
}
