import { useBillableStaffingContext } from 'contexts/Reporting/BillableStaffingContextProvider';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { ReactElement } from 'react';
import { Line } from 'react-chartjs-2';

import { MONTH_IN_YEAR_LABELS } from '../utils';

export default function IntermissionRateGraph(): ReactElement {
  const { billedEtpsData, unbillableEtpsData } = useBillableStaffingContext();

  const intermissionRateData = _.zipWith(
    billedEtpsData,
    unbillableEtpsData,
    (a, b) => totalFormat((b / (a + b)) * 100).replace(',', '.')
  );

  const options = {
    responsive: true,
    lineTension: 0.2,
    plugins: {
      colors: {
        enabled: true,
        forceOverride: true,
      },
      legend: {
        display: true,
      },
    },
  };

  const barData = {
    labels: MONTH_IN_YEAR_LABELS,
    datasets: [
      {
        data: intermissionRateData,
        label: "Taux d'intermission (%)",
      },
    ],
  };

  return <Line data={barData} options={options} />;
}
