import PushPinIcon from '@mui/icons-material/PushPin';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import MissionStatusChip from 'components/commons/MissionStatusChip';
import { ExternalActivityModal } from 'components/MissionPage/ExternalActivityModal/ExternalActivityModal';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useUserInfo } from 'components/User/UserProvider';
import { ActivitiesActivityTypeChoices, ActivityNode } from 'generated/graphql';
import moment from 'moment';
import { POLY_DATE } from 'poly-constants';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setSelectedMission } from 'store/activity';
import { isChiefOrDirectorOfActivity, OpacitySuffix } from 'utils';

import { MissionTitle } from './MissionTitle';

interface MissionHeaderProps {
  mission: ActivityNode;
  refetchMission: () => void;
  showStatusChip: boolean;
  showPinButton?: boolean;
}

function MissionHeader({
  mission,
  refetchMission,
  showStatusChip,
  showPinButton,
}: MissionHeaderProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { employee, isComex, isControl, isSuperuser } = useUserInfo();
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );

  const pinMission = () => {
    const newSelectedMissions = [
      ...selectedMissions.filter(
        (filteredMission) => filteredMission.id !== mission.id
      ),
    ];
    if (!selectedMissions.find((m) => m.id === mission.id)) {
      newSelectedMissions.push(mission);
    }
    dispatch(setSelectedMission(newSelectedMissions));
  };

  const [isExternalActivityModalOpen, setIsExternalActivityModalOpen] =
    useState(false);
  const isMissionExernal = mission.type === ActivitiesActivityTypeChoices.Ext;
  const isMissionInitialized = !!mission?.billingInformation;
  const isEmployeeChiefOrDirectorOfActivity = isChiefOrDirectorOfActivity(
    mission,
    employee
  );
  const isEmployeeAllowedToUpdateMissionInfo =
    isEmployeeChiefOrDirectorOfActivity || isComex || isControl || isSuperuser;
  const showActivityUpdateButton =
    !!refetchMission &&
    isMissionExernal &&
    isMissionInitialized &&
    isEmployeeAllowedToUpdateMissionInfo;

  return (
    <Stack
      component={Paper}
      direction={'column'}
      gap={2}
      padding={2.5}
      sx={{
        bgcolor:
          theme.palette.tabHeaderBlue.light + OpacitySuffix.FORTY_PERCENT,
      }}
      elevation={0}
      borderRadius={2}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems="flex-start"
      >
        <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
          <MissionTitle mission={mission} />
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h2" color={'darkGrey.darker'}>
              {mission?.businessClient?.name}
            </Typography>
            {showActivityUpdateButton && (
              <>
                <PongoButton
                  sx={{
                    width: '36px',
                    minWidth: '36px',
                    height: '36px',
                    p: 0,
                    borderRadius: 1,
                  }}
                  variant="contained"
                  buttonStyle="secondary"
                  startIcon={<SettingsRoundedIcon fontSize="large" />}
                  onClick={() => setIsExternalActivityModalOpen(true)}
                />
                <ExternalActivityModal
                  open={isExternalActivityModalOpen}
                  activity={mission}
                  onClose={() => setIsExternalActivityModalOpen(false)}
                  refetchActivityInfo={refetchMission}
                />
              </>
            )}
            {showPinButton && (
              <PongoButton
                sx={{
                  width: '36px',
                  minWidth: '36px',
                  height: '36px',
                  p: 0,
                  borderRadius: 1,
                }}
                variant="contained"
                buttonStyle="secondary"
                startIcon={<PushPinIcon fontSize="large" />}
                onClick={pinMission}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'start'} gap={2}>
        <Typography color={'textSecondary'} sx={{ fontSize: '14px' }}>
          {moment(mission.startDate).format(POLY_DATE)} &gt;{' '}
          {moment(mission.expirationDate).format(POLY_DATE)}
        </Typography>
      </Stack>
      {showStatusChip && mission && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: '4px' }}>
          <MissionStatusChip activity={mission} />
        </Box>
      )}
    </Stack>
  );
}

export default MissionHeader;
