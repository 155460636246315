import { SxProps, Theme } from '@mui/material';
import ProfitabilityDataComingCell from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/Rows/Cells/ProfitabilityDataComingCell';
import { StyledCell } from 'components/Reporting/Profitability/utils';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

interface ProfitabilityEmployeeRenderValuesProps {
  month: Moment;
  totalCollaboratorTime: number;
  adrCollaborator: number;
  sx?: SxProps<Theme>;
  isExcludingIntern: boolean;
  isEmployeeInternOnPeriod: boolean;
}

export default function ProfitabilityEmployeeRenderValues({
  month,
  totalCollaboratorTime,
  adrCollaborator,
  sx,
  isExcludingIntern,
  isEmployeeInternOnPeriod,
}: ProfitabilityEmployeeRenderValuesProps) {
  if (month.isAfter(moment(), 'month')) {
    return <ProfitabilityDataComingCell />;
  }

  if (isExcludingIntern && isEmployeeInternOnPeriod) {
    return (
      <OutOfRangeTableCell
        title={'Le collaborateur est stagiaire sur cette période'}
        colSpan={3}
      />
    );
  }
  return (
    <>
      <StyledCell sx={sx}>
        <div>
          {adrCollaborator
            ? totalFormat(
                adrCollaborator,
                BillingPurchaseOrderCurrencyChoices.Eur
              )
            : '-'}
        </div>
      </StyledCell>
      <StyledCell
        sx={{
          ...{
            borderLeft: '1px solid',
            borderColor: 'darkGrey.main',
          },
          ...sx,
        }}
      >
        <div>{totalCollaboratorTime}</div>
      </StyledCell>
      <StyledCell
        sx={{
          ...{
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderColor: 'darkGrey.main',
          },
          ...sx,
        }}
      >
        <div>
          {adrCollaborator
            ? totalFormat(
                adrCollaborator * totalCollaboratorTime,
                BillingPurchaseOrderCurrencyChoices.Eur
              )
            : '-'}
        </div>
      </StyledCell>
    </>
  );
}
