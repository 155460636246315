import { Add } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PolySubTitle from 'components/commons/PolySubTitle';
import PolyTitle from 'components/commons/PolyTitle';
import TabHeader from 'components/commons/TabHeader';
import { displayRangeMonthPeriod } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import CreateOrUpdateBillModal from 'components/MissionFollowUp/BillDetail/Modals/CreateOrUpdateBillModal';
import DisplayBillStatus from 'components/MissionFollowUp/OverallListBill/DisplayStatus/DisplayBillStatus';
import StatusGroupEditButtons from 'components/MissionFollowUp/OverallListBill/GroupEditButtons/StatusGroupEditButtons';
import PolyDataGrid from 'components/MUIOverload/PolyDataGrid';
import StringCell from 'components/MUIOverload/PolyDataGrid/components/StringCell';
import { PolyDataGridColumn } from 'components/MUIOverload/PolyDataGrid/PolyDataGridHead';
import PongoButton from 'components/MUIOverload/PongoButton';
import { EmployeeOccupations, useUserInfo } from 'components/User/UserProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  BillingBillStatusChoices,
  BillingPurchaseOrderCurrencyChoices,
  BillNode,
  PurchaseOrderNode,
  useFetchBillsOfPurchaseOrderQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE } from 'poly-constants';
import { default as React, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import {
  addSelectedBill,
  removeSelectedBill,
  setCurrentBill,
  setCurrentPurchaseOrder,
} from 'store/purchaseOrder';
import { compareFacturationTable, isChiefOrDirectorOfActivity } from 'utils';

import PurchaseOrderModal from '../../../commons/PurchaseOrderModal';
import { PoFormMode } from '../../../commons/PurchaseOrderModal/PurchaseOrderForm';
import ListBillsOptions from './ListBillsOptions';
import PurchaseOrderSummary from './PurchaseOrderSummary';

export default function ListBills() {
  const { employee } = useUserInfo();
  const groupEdit = useSelector((state) => state.purchaseOrder.groupEdit);
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const missionId = useSelector((state) => state.activity.currentMission.id);
  const poId = new URLSearchParams(search).get('po_id');
  const currentMission = useSelector((state) => state.activity.currentMission);
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const [openModal, setOpenModal] = useState(false);
  const [openDupModal, setOpenDupModal] = useState(false);
  const [billModalOpen, setBillModalOpen] = useState(false);
  const isActivityChiefOrDirector = isChiefOrDirectorOfActivity(
    currentMission,
    employee
  );

  useFetchBillsOfPurchaseOrderQuery({
    variables: { purchaseOrderId64: poId ? poId : '' },
    onCompleted: (data) => {
      const purchaseOrder = data.purchaseOrder as PurchaseOrderNode;
      dispatch(
        setCurrentPurchaseOrder({
          ...purchaseOrder,
          bills: purchaseOrder.bills?.map((bill) => ({
            ...bill,
            purchaseOrder,
          })),
        } as PurchaseOrderNode)
      );
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });
  const rowsActive = (
    _orderBy?: string | string[],
    _order?: 'asc' | 'desc'
  ) => {
    return _.clone(currentPurchaseOrder.bills || []).sort(
      compareFacturationTable(_orderBy, _order)
    );
  };

  function redirectToBillView(bill: BillNode) {
    dispatch(setCurrentBill({} as BillNode));
    history.push({
      pathname: `/mission-followup/missions/details/bill`,
      search: `?mission_id=${missionId}&po_id=${poId}&bill_id=${bill.id}`,
    });
  }
  function selectedCallback(element: BillNode, selected: boolean) {
    if (selected) {
      dispatch(addSelectedBill(element));
    } else {
      dispatch(removeSelectedBill(element));
    }
  }

  return (
    <>
      <TabHeader
        title={
          <PolyTitle>
            {currentMission.billingType ===
            ActivitiesActivityBillingTypeChoices.Tm
              ? 'BDC Régie : '
              : 'BDC Forfait : '}
            <PolyTitle sx={{ display: 'inline' }} color={'text.secondary'}>
              {currentPurchaseOrder.name}
            </PolyTitle>
          </PolyTitle>
        }
        actionComponents={
          <ListBillsOptions
            handleClickEdit={() => setOpenModal(true)}
            handleClickDuplicate={() => setOpenDupModal(true)}
          />
        }
        showPinButton={false}
      />
      <PurchaseOrderSummary />
      <PurchaseOrderModal
        open={openModal}
        closeModal={() => setOpenModal(false)}
        purchaseOrderId={poId ? poId : ''}
        mode={poId ? PoFormMode.EDIT : PoFormMode.CREATE}
      />
      <PurchaseOrderModal
        open={openDupModal}
        closeModal={() => setOpenDupModal(false)}
        purchaseOrderId={poId ? poId : ''}
        mode={PoFormMode.DUPLICATE}
      />
      <Grid container item>
        <Grid
          item
          container
          xs={12}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={currentPurchaseOrder.hasTemporaryDocument ? 3 : 4}
            sx={{ mb: 4, mt: 5 }}
          >
            <PolySubTitle>
              Factures
              {currentMission.billingType ===
              ActivitiesActivityBillingTypeChoices.Tm
                ? ' Régie'
                : ' Forfait'}
            </PolySubTitle>
          </Grid>
          {isActivityChiefOrDirector && (
            <Grid container item xs={8} sx={{ justifyContent: 'flex-end' }}>
              <Grid item sx={{ mt: 4, mb: 2.5 }}>
                <StatusGroupEditButtons />
              </Grid>
              <>
                {currentPurchaseOrder.hasTemporaryDocument && (
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        my: 3,
                        mr: 3,
                        textAlign: 'end',
                        color: 'text.secondary',
                      }}
                    >
                      Vous devez avoir un justificatif sur votre bon de commande
                      afin de créer des factures
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Box sx={{ mt: 4, mb: 2.5 }}>
                    <PongoButton
                      variant="contained"
                      size="small"
                      onClick={() => {
                        dispatch(setCurrentBill({} as BillNode));
                        setBillModalOpen(true);
                      }}
                      startIcon={<Add />}
                      disabled={currentPurchaseOrder.hasTemporaryDocument}
                      sx={{ mx: 0 }}
                    >
                      Ajouter
                    </PongoButton>
                    <CreateOrUpdateBillModal
                      open={billModalOpen}
                      onClose={() => setBillModalOpen(false)}
                    />
                  </Box>
                </Grid>
              </>
            </Grid>
          )}
        </Grid>
      </Grid>
      {currentPurchaseOrder.bills ? (
        <PolyDataGrid
          columns={buildBillColumns(currentPurchaseOrder.currency)}
          getRows={rowsActive}
          isSelectable={groupEdit}
          selectedRowsCallback={selectedCallback}
          isRowSelectEnabled={(element) =>
            isRowSelectEnabled(
              element,
              employee?.occupation as EmployeeOccupations
            )
          }
          getRowId={({ id }) => id}
          rowsCallback={redirectToBillView}
          sx={{ height: '50px' }}
        />
      ) : (
        <LoadingPlaceholder />
      )}
    </>
  );
}

function buildBillColumns(
  currency: BillingPurchaseOrderCurrencyChoices
): PolyDataGridColumn<DeepExtractTypeSkipArrays<BillNode, []>>[] {
  return [
    {
      headerName: 'Libellé',
      isSortable: true,
      propertyName: 'title',
      width: '200px',
      render: (data) =>
        StringCell({
          data: data.title,
        }),
    },
    {
      headerName: 'Mois facturés',
      propertyName: 'billingMonth',
      isSortable: true,
      width: '150px',
      render: (data) =>
        StringCell({
          data: displayRangeMonthPeriod(
            data.periodBeginning,
            data.periodEnding
          ),
        }),
    },
    {
      headerName: 'À facturer le',
      propertyName: 'date',
      isSortable: true,
      width: '120px',
      render: (data) =>
        StringCell({ data: moment(data.date).format(POLY_DATE) }),
    },
    {
      headerName: 'Montant (HT)',
      propertyName: 'total',
      isSortable: true,
      width: '140px',
      render: (data) =>
        StringCell({
          data: totalFormat(data.total, currency),
        }),
    },
    {
      headerName: 'Statut',
      isSortable: true,
      propertyName: 'status',
      width: '100px',
      render: (data) =>
        DisplayBillStatus({ status: data.status, justify: 'start' }),
    },
  ];
}

function isRowSelectEnabled(
  element: BillNode,
  occupation: EmployeeOccupations
): boolean {
  if (
    occupation === EmployeeOccupations.CONTROL ||
    occupation === EmployeeOccupations.DG
  ) {
    return element.status === BillingBillStatusChoices.ToBill;
  } else {
    return element.status === BillingBillStatusChoices.Draft;
  }
}
