import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useBillableStaffingContext } from 'contexts/Reporting/BillableStaffingContextProvider';
import React, { ReactElement } from 'react';

export default function IntermissionRateSwitch(): ReactElement {
  const { isIntermissionRateView, setIsIntermissionRateView } =
    useBillableStaffingContext();
  return (
    <Box sx={{ display: 'flex' }}>
      <FormControlLabel
        label={
          <Stack direction="row">
            <Typography>{"Taux d'intermission (%)"}</Typography>
            <Tooltip
              arrow
              title={
                '(ETP Non Facturés / (ETP Non Facturés + ETP Facturés)) * 100'
              }
              placement={'bottom'}
            >
              <InfoIcon
                sx={{
                  transform: 'scale(0.7)',
                  position: 'relative',
                  color: 'text.secondary',
                }}
              />
            </Tooltip>
          </Stack>
        }
        control={<Switch />}
        onChange={() => setIsIntermissionRateView((state) => !state)}
        checked={isIntermissionRateView}
        labelPlacement="end"
      />
    </Box>
  );
}
