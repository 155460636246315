import { FilterData } from 'components/Reporting/Indicators/Filter/utils';
import { StaffingAnalysisNode } from 'generated/graphql';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export interface BillableStaffingContext {
  isIntermissionRateView: boolean;
  optionsSelected: FilterData[];
  staffingAnalysisList: StaffingAnalysisNode[];
  billedEtpsData: number[];
  unbillableEtpsData: number[];
  undeclaredEtpsData: number[];
  leaveEtpsData: number[];
  setIsIntermissionRateView: React.Dispatch<React.SetStateAction<boolean>>;
  setOptionsSelected: (param: FilterData[]) => void;
  setStaffingAnalysisList: React.Dispatch<
    React.SetStateAction<StaffingAnalysisNode[]>
  >;
  setBilledEtpsData: React.Dispatch<React.SetStateAction<number[]>>;
  setUnbillableEtpsData: React.Dispatch<React.SetStateAction<number[]>>;
  setUndeclaredEtpsData: React.Dispatch<React.SetStateAction<number[]>>;
  setLeaveEtpsData: React.Dispatch<React.SetStateAction<number[]>>;
}

export const BillableStaffingContext = createContext<BillableStaffingContext>({
  isIntermissionRateView: false,
  optionsSelected: [],
  staffingAnalysisList: [],
  billedEtpsData: [],
  unbillableEtpsData: [],
  undeclaredEtpsData: [],
  leaveEtpsData: [],
  setIsIntermissionRateView: () => {
    throw new Error('function setOptionSelected not set');
  },
  setOptionsSelected: () => {
    throw new Error('function setOptionSelected not set');
  },
  setStaffingAnalysisList: () => {
    throw new Error('function setOptionSelected not set');
  },
  setBilledEtpsData: () => {
    throw new Error('function setOptionSelected not set');
  },
  setUnbillableEtpsData: () => {
    throw new Error('function setOptionSelected not set');
  },
  setUndeclaredEtpsData: () => {
    throw new Error('function setOptionSelected not set');
  },
  setLeaveEtpsData: () => {
    throw new Error('function setOptionSelected not set');
  },
});

export function useBillableStaffingContext() {
  const billableStaffingContext = useContext(BillableStaffingContext);
  if (!billableStaffingContext) {
    throw new Error('useContext() cannot be called on such a page');
  }
  return billableStaffingContext;
}

export default function BillableStaffingContextProvider(
  properties: PropsWithChildren<{}>
) {
  const [isIntermissionRateView, setIsIntermissionRateView] = useState(false);
  const [optionsSelected, setOptionsSelected] = useState<FilterData[]>([]);
  const [staffingAnalysisList, setStaffingAnalysisList] = useState<
    StaffingAnalysisNode[]
  >([]);
  const [billedEtpsData, setBilledEtpsData] = useState<number[]>([]);
  const [unbillableEtpsData, setUnbillableEtpsData] = useState<number[]>([]);
  const [undeclaredEtpsData, setUndeclaredEtpsData] = useState<number[]>([]);
  const [leaveEtpsData, setLeaveEtpsData] = useState<number[]>([]);

  return (
    <BillableStaffingContext.Provider
      value={{
        isIntermissionRateView: isIntermissionRateView,
        optionsSelected: optionsSelected,
        staffingAnalysisList: staffingAnalysisList,
        billedEtpsData: billedEtpsData,
        unbillableEtpsData: unbillableEtpsData,
        undeclaredEtpsData: undeclaredEtpsData,
        leaveEtpsData: leaveEtpsData,
        setIsIntermissionRateView: setIsIntermissionRateView,
        setOptionsSelected: setOptionsSelected,
        setStaffingAnalysisList: setStaffingAnalysisList,
        setBilledEtpsData: setBilledEtpsData,
        setUnbillableEtpsData: setUnbillableEtpsData,
        setUndeclaredEtpsData: setUndeclaredEtpsData,
        setLeaveEtpsData: setLeaveEtpsData,
      }}
    >
      {properties.children}
    </BillableStaffingContext.Provider>
  );
}
