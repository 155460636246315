import DownloadIcon from '@mui/icons-material/Download';
import { createCsvFile } from 'components/commons/PolyExportButton';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useBillableStaffingContext } from 'contexts/Reporting/BillableStaffingContextProvider';
import { useExportStaffingAnalysisLazyQuery } from 'generated/graphql';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

import { FilterOptionTypes } from '../Filter/utils';

interface BillableStaffingExportProps {
  year: number;
}

export default function BillableStaffingExport({
  year,
}: BillableStaffingExportProps) {
  const { optionsSelected } = useBillableStaffingContext();

  const contractFilter = _.filter(
    optionsSelected,
    (option) => option.__typename === FilterOptionTypes.Contract
  ).map((option) => option.id);
  const gradeFilter = _.filter(
    optionsSelected,
    (option) => option.__typename === FilterOptionTypes.Grade
  ).map((option) => option.id);
  const occupationFilter = _.filter(
    optionsSelected,
    (option) => option.__typename === FilterOptionTypes.Occupation
  ).map((option) => option.id);

  const [exportQuery, { loading }] = useExportStaffingAnalysisLazyQuery({
    onCompleted: (data) => {
      if (data && data.exportStaffingAnalysis) {
        const filename = `analyse_staffing_${year}.csv`;
        createCsvFile(data.exportStaffingAnalysis, filename);
      }
    },
    onError: () => {
      enqueueSnackbar('Erreur lors de la récupération des données', {
        variant: 'error',
      });
    },
  });

  const handleExport = () => {
    exportQuery({
      variables: {
        year: year,
        contractFilter: contractFilter,
        gradeFilter: gradeFilter,
        occupationFilter: occupationFilter,
      },
    });
  };

  return (
    <PongoButton
      buttonStyle="secondary"
      variant="contained"
      onClick={handleExport}
      startIcon={<DownloadIcon />}
      loading={loading}
    >
      Exporter
    </PongoButton>
  );
}
