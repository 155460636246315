import { Typography } from '@mui/material';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import { isTrimesterStart } from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import ProfitabilityNotAssignedCell from 'components/Reporting/Profitability/GlobalView/ProfitabilityTableBody/Rows/Cells/ProfitabilityNotAssignedCell';
import { EmployeeType } from 'components/Reporting/Profitability/MissionView/ProfitabilityTableBody/ProfitabilityMissionViewTableBody';
import ProfitabilityEmployeeRenderValues from 'components/Reporting/Profitability/MissionView/ProfitabilityTableBody/Rows/ProfitabilityEmployeeRenderValues';
import { ActivityNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

import {
  getEmployeeAdrForMonth,
  getEmployeeAdrForTrimester,
  getTotalTimeSpent,
  hasActivityMonitoring,
  isAssigned,
  isEmployeeInternOnPeriod,
} from './ProfitabilityEmployeeRow';

interface ProfitabilityEmployeeSubMissionRowProps {
  employee: EmployeeType;
  isTrimesterView: boolean;
  subActivity: ActivityNode;
  isExcludingIntern: boolean;
}

export default function ProfitabilityEmployeeSubMissionRow({
  employee,
  isTrimesterView,
  subActivity,
  isExcludingIntern,
}: ProfitabilityEmployeeSubMissionRowProps) {
  const { displayedMonths } = useTableHeaderContext();
  const monthsToDisplay = isTrimesterView
    ? _.filter(displayedMonths, (month) => isTrimesterStart(month))
    : displayedMonths;

  const activityMonitorings = _.filter(
    employee.activityMonitoring,
    (am) => am.activity.id === subActivity.id
  );

  return (
    <>
      <BorderedTableCell>
        <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
          {subActivity.name}
        </Typography>
      </BorderedTableCell>
      {_.map(monthsToDisplay, (month) => {
        if (
          !isAssigned(month, isTrimesterView, employee.assignments) &&
          !hasActivityMonitoring(month, isTrimesterView, activityMonitorings)
        ) {
          return (
            <ProfitabilityNotAssignedCell
              key={`month-${month.format('MMMM')}-sub-activity-${
                subActivity.id
              }-employee-${employee.id}`}
            />
          );
        }
        if (!isTrimesterView || isTrimesterStart(month)) {
          const isWarning = !isAssigned(
            month,
            isTrimesterView,
            employee.assignments
          );

          return (
            <ProfitabilityEmployeeRenderValues
              month={month}
              totalCollaboratorTime={getTotalTimeSpent(
                employee,
                activityMonitorings || [],
                month,
                isTrimesterView,
                isExcludingIntern
              )}
              adrCollaborator={
                isTrimesterView
                  ? getEmployeeAdrForTrimester(
                      employee,
                      month,
                      activityMonitorings,
                      isExcludingIntern
                    )
                  : getEmployeeAdrForMonth(employee, month, isExcludingIntern)
              }
              sx={{
                fontSize: '0.8rem',
                ...(isWarning && {
                  border: '1px solid',
                  borderColor: 'orange.main',
                }),
              }}
              key={`month-${month.format('MMMM')}-sub-activity-${
                subActivity.id
              }-employee-${employee.id}`}
              isExcludingIntern={isExcludingIntern}
              isEmployeeInternOnPeriod={isEmployeeInternOnPeriod(
                employee,
                month,
                isTrimesterView
              )}
            />
          );
        }
        return <></>;
      })}
    </>
  );
}
