import { FilterList } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  EmployeesEmployeeOccupationHorsProdChoices,
  EmployeesEmployeeOccupationProdChoices,
} from 'components/Phonebook/utils';
import RoleChip from 'components/Reporting/Indicators/Filter/RoleChip';
import { EmployeesEmployeeContractTypeChoices } from 'generated/graphql';
import _ from 'lodash';
import React, { ReactElement, useEffect, useMemo } from 'react';

import FilterChip from './GraphFilterChip';
import {
  contractTypeList,
  FilterData,
  FilterOptionTypes,
  gradeList,
} from './utils';

interface GraphFilterProps {
  optionsSelected: FilterData[];
  setOptionsSelected: (param: FilterData[]) => void;
  isContractTypeFilterOn?: boolean;
  isGradeFilterOn?: boolean;
  isOccupationFilterOn?: boolean;
}

export default function GraphFilter({
  optionsSelected,
  setOptionsSelected,
  isContractTypeFilterOn,
  isGradeFilterOn,
  isOccupationFilterOn,
}: GraphFilterProps): ReactElement {
  const theme = useTheme();

  const initialValue = useMemo(
    () => [
      ...[
        {
          id: EmployeesEmployeeContractTypeChoices.Cdi,
          __typename: FilterOptionTypes.Contract,
        },
      ],
      ..._(gradeList)
        .map((grade) => ({
          id: grade,
          __typename: FilterOptionTypes.Grade,
        }))
        .value(),
      ..._(EmployeesEmployeeOccupationProdChoices)
        .map((occupation) => ({
          id: occupation,
          __typename: FilterOptionTypes.Occupation,
        }))
        .value(),
    ],
    []
  );

  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popper' : undefined;

  const handlePopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };

  const resetSearchOptions = () => {
    setOptionsSelected([]);
  };

  function deleteFilter(id: string) {
    const remainingOptions = _.filter(
      optionsSelected,
      (option) => option.id !== id
    );
    setOptionsSelected(remainingOptions);
  }

  function addFilter(id: string, type: FilterOptionTypes) {
    const temporaryRole: FilterData = {
      id: id,
      __typename: type,
    };
    optionsSelected.push(temporaryRole);
    const newOptions = _.uniqBy(optionsSelected, 'id');
    setOptionsSelected(newOptions);
  }

  const isSelected = (filter: string, optionsSelected: FilterData[]) => {
    return _.some(
      optionsSelected,
      (option) => (option as FilterData).id === filter
    );
  };

  function onFilter(filter: string, type: FilterOptionTypes) {
    if (isSelected(filter, optionsSelected)) {
      deleteFilter(filter);
      return;
    }
    addFilter(filter, type);
  }

  useEffect(() => {
    if (setOptionsSelected) {
      setOptionsSelected(initialValue);
    }
  }, [initialValue, setOptionsSelected]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <ButtonGroup>
          <PongoButton
            variant="contained"
            buttonStyle="secondary"
            startIcon={<FilterList />}
            endIcon={
              !!optionsSelected.length && (
                <Chip
                  label={optionsSelected.length}
                  color="primary"
                  sx={{
                    height: '24px',
                    width: '24px',

                    '> .MuiChip-label': {
                      fontSize: '14px',
                      paddingLeft: 0,
                      paddingRight: 0,
                    },
                  }}
                />
              )
            }
            onClick={handlePopper}
          >
            Filtres
          </PongoButton>
          {!!optionsSelected.length && (
            <PongoButton
              variant="contained"
              buttonStyle="secondary"
              onClick={resetSearchOptions}
            >
              <DeleteIcon />
            </PongoButton>
          )}
        </ButtonGroup>
      </Box>
      {anchorElement && (
        <ClickAwayListener onClickAway={() => setAnchorElement(null)}>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorElement}
            disablePortal={true}
            placement={'bottom'}
            style={{ zIndex: '1' }}
          >
            <Box
              sx={{
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '2px',
                padding: '16px 32px',
                mt: '5px',
                position: 'relative',
                left: '10px',
                bgcolor: theme.palette.background.paper,
              }}
            >
              <Stack flexDirection="row" gap={2} flexWrap="wrap">
                {isContractTypeFilterOn && (
                  <Stack flexDirection="column">
                    <Typography component="div">Grades</Typography>
                    <Stack flexDirection="column" flexWrap="wrap">
                      {_(gradeList)
                        .map((grade) => (
                          <FilterChip
                            key={grade}
                            status={grade}
                            onClick={() =>
                              onFilter(grade, FilterOptionTypes.Grade)
                            }
                            isSelected={isSelected(grade, optionsSelected)}
                          />
                        ))
                        .value()}
                    </Stack>
                  </Stack>
                )}
                {isGradeFilterOn && (
                  <Stack flexDirection="column">
                    <Typography component="div">Contrats</Typography>
                    <Stack flexDirection="column" flexWrap="wrap">
                      {_(contractTypeList)
                        .map((contractType) => (
                          <FilterChip
                            key={contractType}
                            status={contractType}
                            onClick={() =>
                              onFilter(contractType, FilterOptionTypes.Contract)
                            }
                            isSelected={isSelected(
                              contractType,
                              optionsSelected
                            )}
                          />
                        ))
                        .value()}
                    </Stack>
                  </Stack>
                )}
                {isOccupationFilterOn && (
                  <>
                    <Stack flexDirection="column">
                      <Typography component="div">Rôles Prod</Typography>
                      <Stack
                        flexDirection="column"
                        flexWrap="wrap"
                        maxHeight="350px"
                      >
                        {_(EmployeesEmployeeOccupationProdChoices)
                          .map((occupation) => (
                            <Box key={occupation}>
                              <RoleChip
                                role={occupation}
                                onClick={() =>
                                  onFilter(
                                    occupation,
                                    FilterOptionTypes.Occupation
                                  )
                                }
                                isSelected={isSelected(
                                  occupation,
                                  optionsSelected
                                )}
                              />
                            </Box>
                          ))
                          .value()}
                      </Stack>
                    </Stack>
                    <Stack flexDirection="column">
                      <Typography component="div">Rôles Hors prod</Typography>
                      <Stack
                        flexDirection="column"
                        flexWrap="wrap"
                        maxHeight="350px"
                      >
                        {_(EmployeesEmployeeOccupationHorsProdChoices)
                          .map((occupation) => (
                            <Box key={occupation}>
                              <RoleChip
                                role={occupation}
                                onClick={() =>
                                  onFilter(
                                    occupation,
                                    FilterOptionTypes.Occupation
                                  )
                                }
                                isSelected={isSelected(
                                  occupation,
                                  optionsSelected
                                )}
                              />
                            </Box>
                          ))
                          .value()}
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
}
