import {
  RiCheckLine,
  RiCloseCircleFill,
  RiCloseLine,
  RiDeleteBinFill,
  RiSendPlaneFill,
} from '@remixicon/react';
import CancelBillModal from 'components/MissionFollowUp/BillDetail/Modals/CancelBillModal/CancelBillModal';
import ControlUpdateBillModal from 'components/MissionFollowUp/BillDetail/Modals/ControlUpdateBillModal/ControlUpdateBillModal';
import ControlUpdateToBeCanceledBillModal from 'components/MissionFollowUp/BillDetail/Modals/ControlUpdateBillModal/ControlUpdateToBeCanceledBillModal';
import DeleteBillModal from 'components/MissionFollowUp/BillDetail/Modals/DeleteBillModal/DeleteBillModal';
import ManagerUpdateStatusModal from 'components/MissionFollowUp/BillDetail/Modals/ManagerUpdateBillModal/ManagerUpdateStatusModal';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import {
  BillingBillSendingMethodChoices,
  BillingBillStatusChoices,
} from 'generated/graphql';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'store';
import { isEmployeeChiefOfActivity } from 'utils';

import BillEmailModal from '../BillEmailModal/BillEmailModal';
import { isBillAlreadyBilled } from '../utils';
import SendBillMenu from './SendBillMenu';
import { getBillPreviousStatus } from './utils';

interface UpdateStatusFooterProps {
  groupUpdate?: boolean;
  callBack?: () => void;
}

export default function UpdateStatusFooter({
  groupUpdate,
  callBack,
}: UpdateStatusFooterProps) {
  const SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED =
    !!useEnvironmentVariable('send_bill_email');
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const selectedBills = useSelector(
    (state) => state.purchaseOrder.selectedBills
  );
  const isCurrentBillAlreadyBilled = isBillAlreadyBilled(currentBill);

  const currentActivity = useSelector((state) => state.activity.currentMission);

  const { isControl, isSuperuser, employee } = useUserInfo();

  const [openControlUpdateToBeCanceled, setOpenControlUpdateToBeCanceled] =
    useState(false);
  const [openControlUpdate, setOpenControlUpdate] = useState(false);
  const [openControlSend, setOpenControlSend] = useState(false);
  const [selectedSendingMethod, setSelectedSendingMethod] = useState<
    BillingBillSendingMethodChoices | undefined
  >(undefined);
  const [openCancelBill, setOpenCancelBill] = useState(false);
  const [openManagerUpdate, setOpenManagerUpdate] = useState(false);
  const [openBillEmail, setOpenBillEmail] = useState(false);
  const [openControlDeleteBill, setOpenControlDeleteBill] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const status = groupUpdate ? selectedBills[0].status : currentBill.status;

  const isMissionChiefForActivity = isEmployeeChiefOfActivity(
    currentActivity,
    employee
  );

  const shouldDisplayBillSendMenu =
    status === BillingBillStatusChoices.Billed &&
    SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED &&
    (isControl || isSuperuser);

  const handleClickSendBillMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const updateButtons = [
    {
      title: 'Valider la facture',
      groupTitle: 'Valider édition',
      icon: <RiCheckLine />,
      onClick: () => setOpenManagerUpdate(true),
      display: !isControl || isSuperuser || isMissionChiefForActivity,
      statuses: [BillingBillStatusChoices.Draft],
      needMarginRight: true,
    },
    {
      title: `Marquer comme ${
        SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED ? 'Comptabilisée' : 'Facturée'
      }`,
      groupTitle: 'Valider édition',
      onClick: () => setOpenControlUpdate(true),
      icon: <RiCheckLine />,
      display: isControl || isSuperuser,
      statuses: [BillingBillStatusChoices.ToBill],
    },
    {
      title: 'Ignorer la demande',
      variant: true,
      onClick: () => setOpenControlUpdateToBeCanceled(true),
      icon: <RiCloseLine />,
      display: isControl || isSuperuser,
      statuses: [BillingBillStatusChoices.ToCancel],
    },
    {
      title: 'Supprimer définitivement',
      onClick: () => setOpenControlDeleteBill(true),
      icon: <RiDeleteBinFill />,
      display: (isControl || isSuperuser) && !currentBill.billNumber,
      statuses: [BillingBillStatusChoices.ToCancel],
    },
    {
      title: 'Annuler/Générer un avoir',
      onClick: () => setOpenCancelBill(true),
      icon: <RiCloseCircleFill />,
      display: (isControl || isSuperuser) && isCurrentBillAlreadyBilled,
      statuses: [BillingBillStatusChoices.ToCancel],
    },
  ];

  return (
    <React.Fragment>
      {_.map(updateButtons, (button, index) => {
        if (!button.display || !_.includes(button.statuses, status)) {
          return <React.Fragment key={`${button.title}-${index}`} />;
        }
        return (
          <PongoButton
            variant={'contained'}
            buttonStyle={button.variant ? 'secondary' : 'primary'}
            key={`${button.title}-${index}`}
            onClick={() => {
              button.onClick();
            }}
            size={groupUpdate ? 'small' : 'medium'}
            startIcon={button.icon}
            sx={{ mr: button.needMarginRight ? 1 : 0 }}
          >
            {groupUpdate ? button.groupTitle : button.title}
          </PongoButton>
        );
      })}
      {shouldDisplayBillSendMenu && (
        <PongoButton
          variant={'contained'}
          buttonStyle={'primary'}
          onClick={handleClickSendBillMenu}
          size={groupUpdate ? 'small' : 'medium'}
          startIcon={<RiSendPlaneFill />}
        >
          {groupUpdate ? 'Envoyer les factures' : 'Envoyer la facture'}
        </PongoButton>
      )}
      <ControlUpdateToBeCanceledBillModal
        open={openControlUpdateToBeCanceled}
        onClose={() => setOpenControlUpdateToBeCanceled(false)}
        oldStatus={getBillPreviousStatus(currentBill)}
      />
      <DeleteBillModal
        open={openControlDeleteBill}
        closeModal={() => setOpenControlDeleteBill(false)}
      />
      <ControlUpdateBillModal
        open={openControlUpdate}
        desiredBillStatus={BillingBillStatusChoices.Billed}
        onClose={() => setOpenControlUpdate(false)}
        onSuccess={callBack}
        groupUpdate={groupUpdate}
      />
      <CancelBillModal
        open={openCancelBill}
        closeModal={() => setOpenCancelBill(false)}
      />
      <ManagerUpdateStatusModal
        open={openManagerUpdate}
        onClose={() => setOpenManagerUpdate(false)}
        groupAction={groupUpdate}
        onSuccess={callBack}
      />
      <SendBillMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setOpenBillEmail={setOpenBillEmail}
        setOpenControlSend={setOpenControlSend}
        setSelectedSendingMethod={setSelectedSendingMethod}
      />
      <BillEmailModal
        open={openBillEmail}
        closeModal={() => setOpenBillEmail(false)}
        setAnchorEl={setAnchorEl}
      />
      <ControlUpdateBillModal
        open={openControlSend}
        desiredBillStatus={BillingBillStatusChoices.Sent}
        desiredSendingMethod={selectedSendingMethod}
        onClose={() => {
          setOpenControlSend(false);
          setSelectedSendingMethod(undefined);
        }}
        onSuccess={callBack}
        groupUpdate={groupUpdate}
      />
    </React.Fragment>
  );
}
