import { styled, SxProps, TableRow, Theme } from '@mui/material';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import { isTrimesterStart } from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { ReactElement } from 'react';

import ProfitabilityDataComingCell from '../../GlobalView/ProfitabilityTableBody/Rows/Cells/ProfitabilityDataComingCell';
import { StyledCell } from '../../utils';
import { getTotalTimeSpent } from '../ProfitabilityTableBody/Rows/ProfitabilityEmployeeRow';
import { EmployeeType } from './ProfitabilityMissionViewTableBody';
import { getEmployeeAdrForMonth } from './Rows/ProfitabilityEmployeeRow';

const StyledBorderedCell = styled(BorderedTableCell)(() => ({
  fontWeight: 'bold',
  textAlign: 'center',
}));

const ProfitabilityMissionTotalRow = ({
  employees,
  isTrimesterView,
  sx,
}: {
  employees: EmployeeType[];
  isTrimesterView: boolean;
  sx?: SxProps<Theme>;
}): ReactElement => {
  const { displayedMonths } = useTableHeaderContext();

  return (
    <TableRow>
      <StyledBorderedCell>Total</StyledBorderedCell>
      {_.map(displayedMonths, (month) => {
        if (!isTrimesterView || isTrimesterStart(month)) {
          if (month.isAfter(moment(), 'month')) {
            return (
              <ProfitabilityDataComingCell
                key={`total-month-${month.format('MMMM')}`}
              />
            );
          }

          const totalTimeSpent = _.sum(
            _.map(employees, (employee) =>
              getTotalTimeSpent(
                employee,
                employee.activityMonitoring || [],
                month,
                false,
                false
              )
            )
          );

          const costs = getTotalCosts(employees, isTrimesterView, month);
          return (
            <React.Fragment key={`total-month-${month.format('MMMM')}`}>
              <StyledCell sx={sx}>
                <div>-</div>
              </StyledCell>
              <StyledCell
                sx={{
                  ...{
                    borderLeft: '1px solid',
                    borderColor: 'darkGrey.main',
                  },
                  ...sx,
                }}
              >
                <div>{totalTimeSpent}</div>
              </StyledCell>
              <StyledCell
                sx={{
                  ...{
                    borderLeft: '1px solid',
                    borderRight: '1px solid',
                    borderColor: 'darkGrey.main',
                  },
                  ...sx,
                }}
              >
                <div>
                  {totalFormat(costs, BillingPurchaseOrderCurrencyChoices.Eur)}
                </div>
              </StyledCell>
            </React.Fragment>
          );
        }
      })}
    </TableRow>
  );
};

function getTotalCosts(
  employees: EmployeeType[],
  isTrimesterView: boolean,
  month: moment.Moment
) {
  let costs = 0;
  _.forEach(employees, (employee) => {
    costs += isTrimesterView
      ? getEmployeeCostForTrimester(employee, month)
      : getEmployeeCostForMonth(employee, month);
  });
  return costs;
}

function getEmployeeCostForMonth(
  employee: EmployeeType,
  month: Moment
): number {
  return (
    getTotalTimeSpent(
      employee,
      employee.activityMonitoring || [],
      month,
      false,
      false
    ) * getEmployeeAdrForMonth(employee, month, false)
  );
}

function getEmployeeCostForTrimester(
  employee: EmployeeType,
  month: Moment
): number {
  const employeeCostFirstMonth = getEmployeeCostForMonth(employee, month);
  const employeeCostSecondMonth = getEmployeeCostForMonth(
    employee,
    month.clone().add(1, 'month')
  );
  const employeeCostThirdMonth = getEmployeeCostForMonth(
    employee,
    month.clone().add(2, 'month')
  );
  return (
    employeeCostFirstMonth + employeeCostSecondMonth + employeeCostThirdMonth
  );
}

export default ProfitabilityMissionTotalRow;
