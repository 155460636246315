import { InfoOutlined } from '@mui/icons-material';
import { TableHead, TableRow, Typography } from '@mui/material';
import { stickyHeaderSx } from 'components/commons/SX/commonSx';
import { BorderedTableCell } from 'components/commons/Tables/FlatTable';
import ArrowsUpdateTrimesterTableHeader, {
  getTrimesterNumber,
  isCurrentTrimester,
  isTrimesterStart,
} from 'components/commons/Tables/Header/ArrowsUpdateTrimesterTableHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';

import { ProfitabilityMissionViewTableHeaderColumnsNames } from './ProfitabilityMissionViewTableHeaderColumnsNames';

export default function ProfitabilityMissionViewTrimesterTableHeader() {
  const { displayedMonths } = useTableHeaderContext();
  const monthsToDisplay = _.filter(displayedMonths, (month) =>
    isTrimesterStart(month)
  );
  const today = moment();
  const needIndicator = (month: Moment) =>
    today.date() < 29 &&
    month.isSame(today.clone().subtract(1, 'month'), 'month');
  return (
    <TableHead sx={stickyHeaderSx}>
      <TableRow>
        <BorderedTableCell sx={{ width: 200 }} />
        {_.map(monthsToDisplay, (month) => {
          const indicator = needIndicator(month) ? (
            <Typography
              color={(theme) => theme.palette.warning.main}
              display={'flex'}
              alignItems={'center'}
            >
              <InfoOutlined />
            </Typography>
          ) : undefined;

          return (
            <BorderedTableCell
              sx={{ textTransform: 'capitalize', p: 0, width: 1 }}
              key={`header-${month.format('MMM')}`}
              colSpan={3}
            >
              <ArrowsUpdateTrimesterTableHeader
                month={month}
                indicator={indicator}
              >
                <Typography
                  fontWeight={isCurrentTrimester(month) ? 'bold' : 'normal'}
                >
                  {`Trimestre ${getTrimesterNumber(month)} (${month.format(
                    'MMM'
                  )} - ${month.clone().add(2, 'month').format('MMM')})`}
                </Typography>
              </ArrowsUpdateTrimesterTableHeader>
            </BorderedTableCell>
          );
        })}
      </TableRow>
      <ProfitabilityMissionViewTableHeaderColumnsNames
        monthsToDisplay={monthsToDisplay}
      />
    </TableHead>
  );
}
