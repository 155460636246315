import { TableCell } from '@mui/material';
import styles from 'components/Revenue/RevenueGlobalTable/styles/TableCell.module.scss';
import React from 'react';

export default function ProfitabilityNotAssignedCell() {
  return (
    <TableCell
      align={'center'}
      colSpan={3}
      className={styles.invalidCell}
    ></TableCell>
  );
}
