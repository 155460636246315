import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import SectionTitle from 'components/commons/SectionTitle';
import { FlatTable } from 'components/commons/Tables/FlatTable';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import YearSelector from 'components/commons/YearSelector/YearSelector';
import ProfitabilityMissionViewMonthTableHeader from 'components/Reporting/Profitability/MissionView/ProfitabilityMissionViewMonthTableHeader';
import ProfitabilityMissionViewTrimesterTableHeader from 'components/Reporting/Profitability/MissionView/ProfitabilityMissionViewTrimesterTableHeader';
import ProfitabilityMissionViewTableBody from 'components/Reporting/Profitability/MissionView/ProfitabilityTableBody/ProfitabilityMissionViewTableBody';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import urlJoin from 'url-join';

export default function ReportingProfitabilityEmployeesCostsTable() {
  const { pathname, search } = useLocation();
  const urlParam = new URLSearchParams(search);
  const selectedYear = urlParam.has('year')
    ? urlParam.get('year')
    : moment().year().toString();
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [isYearSubtracted, setIsYearSubtracted] = useState(false);
  const [isYearAdded, setIsYearAdded] = useState(false);
  const [currentYear, setCurrentYear] = useState(moment(selectedYear));
  const pathHasTrimesterView = pathname.includes('trimester-view');
  const [isTrimesterView, setIsTrimesterView] = useState(pathHasTrimesterView);
  const [isExcludingIntern, setisExcludingIntern] = useState(false);
  const [monthRangeSize, setMonthRangeSize] = useState(isTrimesterView ? 9 : 3);
  function changeView() {
    setIsTrimesterView(!isTrimesterView);
    setMonthRangeSize(isTrimesterView ? 3 : 9);
  }

  function handleWithInternChange(event: React.SyntheticEvent) {
    const target = event.target as HTMLInputElement;
    setisExcludingIntern(target.checked);
  }

  return (
    <TableHeaderContextProvider
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
      monthRangeSize={monthRangeSize}
      monthOffset={isTrimesterView ? -7 : -1}
      isYearSubtracted={isYearSubtracted}
      isYearAdded={isYearAdded}
    >
      <Accordion
        sx={{
          border: '2px solid',
          borderColor: 'standardGrey.dark',
          borderRadius: '20px',
          py: 2.5,
          px: 3.5,
          gap: 3,
          boxShadow: 'none',
          mb: 2,

          '&::before': {
            display: 'none',
          },
        }}
        onChange={(event, expanded) => setIsAccordionExpanded(expanded)}
        expanded={isAccordionExpanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
          <SectionTitle>Coût par collaborateur</SectionTitle>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            my={2}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <YearSelector
                currentYear={currentYear}
                setCurrentYear={(NewYear: moment.Moment) => {
                  setCurrentYear(NewYear);
                  setIsYearSubtracted(false);
                  setIsYearAdded(false);
                }}
                from={moment('2022-01-01')}
                to={moment()}
              />
              <FormControlLabel
                label={
                  <Tooltip title="Vue trimestrielle">
                    <Typography>Vue trimestrielle</Typography>
                  </Tooltip>
                }
                control={<Switch />}
                onChange={changeView}
                checked={isTrimesterView}
                labelPlacement="end"
              />
              <FormControlLabel
                label={
                  <Tooltip title="Sans stagiaires">
                    <Box
                      component="img"
                      alt="without intern logo"
                      src={urlJoin(
                        '/',
                        process.env.PUBLIC_URL,
                        'img',
                        'without-intern.png'
                      )}
                      sx={{ height: '1.5em', width: '1.5em' }}
                    />
                  </Tooltip>
                }
                control={<Switch />}
                onChange={handleWithInternChange}
                checked={isExcludingIntern}
                disableTypography={true}
                labelPlacement="end"
              />
            </Stack>
          </Stack>
          <FlatTable size={'small'} sx={{ tableLayout: 'fixed' }}>
            {isTrimesterView ? (
              <ProfitabilityMissionViewTrimesterTableHeader />
            ) : (
              <ProfitabilityMissionViewMonthTableHeader
                setCurrentYear={setCurrentYear}
                setIsYearSubtracted={setIsYearSubtracted}
                setIsYearAdded={setIsYearAdded}
              />
            )}
            <ProfitabilityMissionViewTableBody
              isTrimesterView={isTrimesterView}
              isExcludingIntern={isExcludingIntern}
            />
          </FlatTable>
        </AccordionDetails>
      </Accordion>
    </TableHeaderContextProvider>
  );
}
